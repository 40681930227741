import React,{ useRef, useEffect,useState } from 'react';
import { IonCard, IonCardTitle, IonIcon, IonAlert } from '@ionic/react';

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';

import { CountyData } from "../../interfaces/CountyData";
import DoughnutGraph  from "../Graphs/DoughnutGraph";
import "./AllCardStyles.css"


interface GraphDataCardprops {
  content: any;
  crashList: CountyData[];
}


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {backgroundColor:'lightgray',},
  [`& .${linearProgressClasses.bar}`]: {borderRadius: 5, backgroundColor: '#ec4561'},
}));


const CrashesCard: React.FC<GraphDataCardprops> =(props:GraphDataCardprops)=> {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    if (props.content.source != "N/A") setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const history = useHistory();

  const handleOkay = () => {
    closeModal();  // Close the modal first
    history.push('/AppCenter/TranscoreAnalytics');  // Navigate to the desired path
  };


return(
  <>
    <IonCard  className='main-container '>
        <IonCardTitle className="my-card-header">
              <div onClick={openModal} className="icon crashes-icon">
                 <IonIcon color="light" ios={props.content.ios} md={props.content.md}/>
              </div>
              <div className='my-card-title'>{props.content.title}</div>
        </IonCardTitle>
        
        <div className="gauge-container">
                 <DoughnutGraph current={props.content.data} ></DoughnutGraph>
        </div>
        
        <IonCardTitle className='center-title'>
                  Crash Rates By County
        </IonCardTitle>
      
        <div className="counties-list">
              {props.crashList.map((county, index) => {
                  return (
                    <div className='county-and-bar' key={county.name}>   
                      <div className='county-and-percentage'>               
                         <div>{county.name} : {county.crashes}</div> 
                         <div className='percentage'> {Math.floor(((county.crashes)/Number(props.content.data))*100)}% </div>
                      </div>
                      <div> 
                          <BorderLinearProgress variant="determinate" value={Math.floor(((county.crashes)/Number(props.content.data))*100)} />
                      </div>
                    </div>
                  ); 
              })} 
        </div>
        <div className='last-updated'>{props.content.updated}</div>
    </IonCard>

    <IonAlert
          isOpen={modalOpen}
          header={props.content.title}
          subHeader={"Source: " + props.content.source}
          message={props.content.description}
          buttons={[{text:"More Information", handler: handleOkay}]}
          onDidDismiss={closeModal}
          cssClass="bigger-alert"
        ></IonAlert>
  </>
   
    );
};
export default CrashesCard;

