
import React from 'react';

import { Doughnut } from 'react-chartjs-2';
const DoughnutGraph: React.FC<any> =(props:any)=> {
    
    let percent=((Number(props.current)*100)/(Number(props.current)+100));
    if(props.card=='freeway')
        {
            percent=Number(props.current);
        }
    const remaining=100-percent;

    const data={
          datasets:[{
            label:'Poll',
            data:[percent,remaining],
            backgroundColor:(context:any)=>{
              const chart=context.chart;
              const {ctx,chartArea}=chart;
              if(!chartArea)
                {
                  return null;
                }
              if(context.dataIndex===0)
                {
                    return getGradient(chart);
                }
                else
                {
                    return 'antiquewhite';
                }
            },
            
            borderColor:(context:any)=>{
              const chart=context.chart;
              const {ctx,chartArea}=chart;
              if(!chartArea)
                {
                  return null;
                }
                if(context.dataIndex===0)
                {
                    return getGradient(chart);
                }
                else
                {
                    return 'antiquewhite';
                }
            },
            
        }]
    }
    //function to set the gradient background color
    function getGradient(chart:any){
      const {ctx,chartArea:{top,bottom,left,right}}=chart;
      const gradientSegment=ctx.createLinearGradient(left,0,right,0);
      gradientSegment.addColorStop(0,'green');
      gradientSegment.addColorStop(0.5,'orange');
      gradientSegment.addColorStop(1,'red');
      return gradientSegment;

    }    
    const options={
        cutoutPercentage: 80,
        rotation:Math.PI,
        circumference: Math.PI,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context:any) => {
                       
                        return `hello:1`;
                      }
                }
            }
        }
        
    }
    //to put the text at center of gauge chart
    const gaugeText={
        id:'gaugeText',
        beforeDatasetsDraw(chart:any,args:any,pluginOptions:any){
            const {ctx,chartArea:{top,bottom,left,right,width,height}}=chart;
            const xCenter=chart.getDatasetMeta(0).data[0]._model.x;
            const yCenter=chart.getDatasetMeta(0).data[0]._model.y;    
            ctx.save();
            ctx.fillStyle='black';
            if(props.card=='congestion'){
                ctx.font='bold 50px sans-serif';
            }
            else{
                ctx.font='bold 60px sans-serif';
            }
            ctx.textAlign='center';
            ctx.textBaseline='bottom'
            
            if(props.card=='freeway'){
            ctx.fillText(props.current+'%',xCenter,yCenter)
            }
            else{
                ctx.fillText(props.current,xCenter,yCenter)
            }

        }
    
    }
    return(
        <Doughnut data={data} options={options} plugins={[gaugeText]} ></Doughnut>
    );

};
export default DoughnutGraph;