import React,{ useState } from 'react';
import { IonCard, IonCardTitle, IonIcon, IonAlert } from '@ionic/react';


import { CountyData } from "../../interfaces/CountyData";


import Table from 'react-bootstrap/Table';


import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import DoughnutGraph  from "../Graphs/DoughnutGraph";

import "./AllCardStyles.css"

interface GraphDataCardprops {
  content: any;
  crashList: CountyData[];
  newdata:any;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {backgroundColor:'lightgray',},
  [`& .${linearProgressClasses.bar}`]: {borderRadius: 5,backgroundColor: '#ec4561'},
}));


const GraphDataCard: React.FC<GraphDataCardprops> =(props:GraphDataCardprops)=> {

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
     setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const history = useHistory();

  const handleOkay = () => {
    closeModal();  // Close the modal first
    history.push('/AppCenter/Congestion');  // Navigate to the desired path
  };




    return(
  <>
    <IonCard  className='main-container'>
        <IonCardTitle className="my-card-header">
              <div onClick={openModal} className="icon congestion-icon">
                <IonIcon  color="light" ios={props.content.ios} md={props.content.md}/>
              </div>
              <div className='my-card-title'>Congestion Miles</div>
        </IonCardTitle>
        
        <div className="congestion-doughnut-table">
           <div className='congestion-doughnut'>
              <DoughnutGraph current={props.newdata.congested_miles} card='congestion' ></DoughnutGraph>
           </div>
           <div className='congestion-table'>
              <Table striped bordered hover>
                  <tbody>
                      <tr>
                        <td>PTI</td>
                        <td>{props.newdata.pti}</td>
                      </tr>
                      <tr>
                        <td>TTI</td>
                        <td>{props.newdata.tti}</td>
                      </tr>
                  </tbody>
                </Table>
            </div>
        </div>
        
        <IonCardTitle className='center-title' style={{ color: 'black' }}>
                  Congestion By County
        </IonCardTitle>

        <div className="counties-list">
              {props.newdata.countyCongestion.map((county:any, index:number) => {
                  return (
                    <div className='county-and-bar' key={county.name}>   
                      <div className='county-and-percentage'>               
                         <div>{county.name} : {Math.floor(county.miles)}</div> 
                         <div className='percentage'> {Math.floor(((county.miles)/Number(props.newdata.congested_miles))*100)}% </div>
                      </div>
                      <div> 
                         <BorderLinearProgress variant="determinate" value={Math.floor(((county.miles)/Number(props.newdata.congested_miles))*100)} />
                      </div>
                    </div>
                  ); 
              })} 
        </div>

        <div className='last-updated'>{props.content.updated}</div>
    </IonCard>
    <IonAlert
          isOpen={modalOpen}
          header={props.content.title}
          subHeader={"Congestion Information"}
          message={"Click below for more information on congestion"}
          buttons={[{text:"More Information", handler: handleOkay}]}
          onDidDismiss={closeModal}
          cssClass="bigger-alert"
        ></IonAlert>
    

  </>
   
    );
};
export default GraphDataCard;

